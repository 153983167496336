export const RaritiesMapping = {
  Common: '0',
  Uncommon: '1',
  Rare: '2',
  Epic: '3',
  Legendary: '4'
}

export const PlayermonRarityTypeMapping = {
  [RaritiesMapping.Common]: 'Common',
  [RaritiesMapping.Uncommon]: 'Uncommon',
  [RaritiesMapping.Rare]: 'Rare',
  [RaritiesMapping.Epic]: 'Epic',
  [RaritiesMapping.Legendary]: 'Legendary'
}

export enum PlayermonTypeMapping {
  Cosmos = 'Cosmos',
  Wharfe = 'Wharfe',
  Gaia = 'Gaia',
  Igneous = 'Igneous',
  Constellation = 'Constellation',
  Pegasus = 'Pegasus',
  Nautilus = 'Nautilus',
  Petunia = 'Petunia',
  Razortooth = 'Razortooth',
  Dynamite = 'Dynamite',
  Wick = 'Wick',
  Kraken = 'Kraken',
  MoonMoth = 'MoonMoth',
  Crawleaf = 'Crawleaf',
  Artificer = 'Artificer',
  Juggler = 'Juggler',
  Developer = 'Developer',
  Phoenix = 'Phoenix',
  Ankh = 'Ankh',
  Clamhorn = 'Clamhorn',
  Clockwork = 'Clockwork',
  Hermes = 'Hermes',
  Ursa = 'Ursa',
  Venus = 'Venus',
  Hollow = 'Hollow',
  Shinu = 'Shinu',
  Artillery = 'Artillery',
  Doktha = 'Doktha',
  Ingenium = 'Ingenium',
  Wish = 'Wish',
  Glitter = 'Glitter',
  Cinders = 'Cinders',
  Ivy = 'Ivy',
  Phantacle = 'Phantacle',
  Eclipse = 'Eclipse',
  Umi = 'Umi'
}

export enum LeaderboardStatus {
  FUTURE = 0,
  CURRENT = 1,
  COMPLETED = 2
}

export const PlayermonBodyPartLabelV2 = {
  Cosmos: '1',
  Wharfe: '2',
  Gaia: '3',
  Igneous: '4',
  Constellation: '5',
  Pegasus: '6',
  Nautilus: '7',
  Petunia: '8',
  Razortooth: '9',
  Dynamite: '10',
  Wick: '11',
  Kraken: '12',
  MoonMoth: '13',
  Crawleaf: '14',
  Artificer: '15',
  Juggler: '16',
  Developer: '17',
  Phoenix: '18',
  Ankh: '19',
  Clamhorn: '20',
  Clockwork: '21',
  Hermes: '22',
  Ursa: '23',
  Venus: '24',
  Hollow: '25',
  Shinu: '26',
  Artillery: '27',
  Doktha: '28',
  Ingenium: '29',
  Wish: '30',
  Glitter: '31',
  Cinders: '32',
  Ivy: '33',
  Phantacle: '34',
  Eclipse: '35',
  Umi: '36'
}

export const PlayermonBodyPartLabelMapping = {
  [PlayermonBodyPartLabelV2.Cosmos]: 'Cosmos',
  [PlayermonBodyPartLabelV2.Wharfe]: 'Wharfe',
  [PlayermonBodyPartLabelV2.Gaia]: 'Gaia',
  [PlayermonBodyPartLabelV2.Igneous]: 'Igneous',
  [PlayermonBodyPartLabelV2.Constellation]: 'Constellation',
  [PlayermonBodyPartLabelV2.Pegasus]: 'Pegasus',
  [PlayermonBodyPartLabelV2.Nautilus]: 'Nautilus',
  [PlayermonBodyPartLabelV2.Petunia]: 'Petunia',
  [PlayermonBodyPartLabelV2.Razortooth]: 'Razortooth',
  [PlayermonBodyPartLabelV2.Dynamite]: 'Dynamite',
  [PlayermonBodyPartLabelV2.Wick]: 'Wick',
  [PlayermonBodyPartLabelV2.Kraken]: 'Kraken',
  [PlayermonBodyPartLabelV2.MoonMoth]: 'MoonMoth',
  [PlayermonBodyPartLabelV2.Crawleaf]: 'Crawleaf',
  [PlayermonBodyPartLabelV2.Artificer]: 'Artificer',
  [PlayermonBodyPartLabelV2.Juggler]: 'Juggler',
  [PlayermonBodyPartLabelV2.Developer]: 'Developer',
  [PlayermonBodyPartLabelV2.Phoenix]: 'Phoenix',
  [PlayermonBodyPartLabelV2.Ankh]: 'ANKH',
  [PlayermonBodyPartLabelV2.Clamhorn]: 'Clamhorn',
  [PlayermonBodyPartLabelV2.Clockwork]: 'Clockwork',
  [PlayermonBodyPartLabelV2.Hermes]: 'Hermes',
  [PlayermonBodyPartLabelV2.Ursa]: 'URSA',
  [PlayermonBodyPartLabelV2.Venus]: 'Venus',
  [PlayermonBodyPartLabelV2.Hollow]: 'Hollow',
  [PlayermonBodyPartLabelV2.Shinu]: 'Shinu',
  [PlayermonBodyPartLabelV2.Artillery]: 'Artillery',
  [PlayermonBodyPartLabelV2.Doktha]: 'Doktha',
  [PlayermonBodyPartLabelV2.Ingenium]: 'Ingenium',
  [PlayermonBodyPartLabelV2.Wish]: 'Wish',
  [PlayermonBodyPartLabelV2.Glitter]: 'Glitter',
  [PlayermonBodyPartLabelV2.Cinders]: 'Cinders',
  [PlayermonBodyPartLabelV2.Ivy]: 'Ivy',
  [PlayermonBodyPartLabelV2.Phantacle]: 'Phantacle',
  [PlayermonBodyPartLabelV2.Eclipse]: 'Eclipse',
  [PlayermonBodyPartLabelV2.Umi]: 'Umi'
}
